/* nunito-200 - latin-ext_latin */
@font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 200;
    src: url('./fonts/nunito-v22-latin-ext_latin-200.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-200.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-200.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-200.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-300 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 300;
    src: url('./fonts/nunito-v22-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-300.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-300.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-300.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-regular - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    src: url('./fonts/nunito-v22-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-regular.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-500 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    src: url('./fonts/nunito-v22-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-500.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-500.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-500.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-600 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 600;
    src: url('./fonts/nunito-v22-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-600.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-600.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-600.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-700 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 700;
    src: url('./fonts/nunito-v22-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-700.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-800 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 800;
    src: url('./fonts/nunito-v22-latin-ext_latin-800.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-800.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-800.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-800.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-900 - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 900;
    src: url('./fonts/nunito-v22-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-900.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-200italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 200;
    src: url('./fonts/nunito-v22-latin-ext_latin-200italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-200italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-200italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-200italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-300italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 300;
    src: url('./fonts/nunito-v22-latin-ext_latin-300italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-300italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-300italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 400;
    src: url('./fonts/nunito-v22-latin-ext_latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-500italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 500;
    src: url('./fonts/nunito-v22-latin-ext_latin-500italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-500italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-500italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-600italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 600;
    src: url('./fonts/nunito-v22-latin-ext_latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-600italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-600italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-700italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 700;
    src: url('./fonts/nunito-v22-latin-ext_latin-700italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-700italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-700italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-800italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 800;
    src: url('./fonts/nunito-v22-latin-ext_latin-800italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-800italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-800italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-800italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-800italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-800italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }
  /* nunito-900italic - latin-ext_latin */
  @font-face {
    font-family: 'Nunito';
    font-style: italic;
    font-weight: 900;
    src: url('./fonts/nunito-v22-latin-ext_latin-900italic.eot'); /* IE9 Compat Modes */
    src: local(''),
         url('./fonts/nunito-v22-latin-ext_latin-900italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('./fonts/nunito-v22-latin-ext_latin-900italic.woff2') format('woff2'), /* Super Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-900italic.woff') format('woff'), /* Modern Browsers */
         url('./fonts/nunito-v22-latin-ext_latin-900italic.ttf') format('truetype'), /* Safari, Android, iOS */
         url('./fonts/nunito-v22-latin-ext_latin-900italic.svg#Nunito') format('svg'); /* Legacy iOS */
  }