.graph-button {
    margin-right: 4px;
    font-size: 14px;
}


.double-button-first {
    margin-right: 0;
    --border-radius: 0;
    --box-shadow: none;
}

.double-button-first::part(native) {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.double-button-first-non-admin {
    margin-right: 0;
    --border-radius: 0;
    --box-shadow: none;
}

.double-button-first-non-admin::part(native) {

    border-radius: 10px;
}

.double-button-second {
    margin-left: 0;
    --border-radius: 0;
    --box-shadow: none;
}

.double-button-second::part(native) {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}




// ion-breadcrumbs ion-breadcrumb.breadcrumb-active::part(separator)
// {
//     visibility: hidden;
// }

ion-breadcrumbs ion-breadcrumb.breadcrumb-active {
    font-weight: 700;
    color: rgb(82, 96, 255);
}

ion-modal.modal-image {
    --height: auto;
    --width: auto;
}

// ion-modal.modal-image::part(content) {
// 	border-top-left-radius: 13px;
// 	border-top-right-radius: 13px;
// }
ion-modal.modal-image ion-img {
    height: 90vh;
}

canvas {
    padding-right: 15px;
}


// .row-hover {
//     transition: box-shadow .1s ease-in-out,transform .1s ease-in-out;
//     will-change: box-shadow,transform;
//     cursor: pointer;
// }

// .row-hover:hover {
//     box-shadow: 0 2px 8px 0 rgba(99, 99, 99, 0.2);
//     transform: scale(1.03);
//     background: rgb(255, 255, 255);
// }
.navigation-bar {
    position: sticky;
    top: 0;
    z-index: 3;
    padding-top: 10px;
    background: white;
}

.MuiDataGrid-main .MuiDataGrid-cell {
    padding-bottom: 5px !important;
    padding-top: 5px !important;
}