.janek-shadow {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 20px 30px;
    border-radius: 9px;
    border: 1px solid rgba(224, 224, 224, 1);
}

.report-calendar .calendar-day-active {
    pointer-events: none;
}

.MuiDataGrid-columnHeaders~div:nth-of-type(3) {
    display: none;
}

.MuiDataGrid-cell {
    font-weight: 600;
}

.arrival-done-count {
    font-weight: 700;
    margin-right: 5px;
    color: rgb(72, 169, 72);
}

.arrival-undone-count {
    font-weight: 700;
    margin-left: 5px;
    color: rgb(255, 0, 0);
}

.delivery-done-count {
    font-weight: 500;
    margin-right: 5px;
    color: rgb(72, 169, 72);
}

.undelivery-count {
    font-weight: 500;
    margin-left: 5px;
    color: rgb(255, 0, 0);
}

// .MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
//     display: none;
// }

.datagrid-hide-select-all .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer {
    display: none;
}

.datagrid-hide-grouping-value .MuiDataGrid-groupingCriteriaCell span {
    visibility: hidden;
}

.datagrid-hide-grouping-value .MuiDataGrid-groupingCriteriaCell {
    pointer-events: none;
}

.MuiDataGrid-root .datagrid-hide-row-border .MuiDataGrid-cell {
    border-bottom: none;
}

.MuiDataGrid-root .datagrid-hide-row-border-group-row .MuiDataGrid-cell {
    border-bottom: none;
    border-top: 1px solid rgba(224, 224, 224, 1);
}