body.dark {

    .navigation-bar {
        background: #121212;
    }

    table {
        background-color: rgb(34, 36, 40);
    }

    .modal-diet-list {
        background-color: rgb(34, 36, 40);
    }

}